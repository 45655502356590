<template>
  <div>
    <parcelamento-base
      :filtros-rapidos-custom="filtrosRapidos"
      :filtros-custom="filtros"
      colunas-custom
      :novas-colunas="colunas"
      :get-color-custom="getColorTag"
      place-holder="Busca por: CNPJ/CPF, Razão social, Nº Parcelamento, Modalidade, Data negociação"
    >
      <template
        slot="colunasCustom"
        slot-scope="{props} "
      >
        <div
          v-if="props.column.field === 'parcelasEmAtraso'"
          class="text-center"
        >
          <b-badge
            :variant="props.row[props.column.field] ? 'light-danger':'light-success'"
          >
            {{ props.row[props.column.field] ? 'Sim': 'Não' }}
          </b-badge>
        </div>
      </template>
    </parcelamento-base>
  </div>
</template>

<script>
import colunas from './shared/colunas'

export default {
  name: 'ParcelamentoPgfnEmissao',
  components: {
    ParcelamentoBase: () => import('../ParcelamentoView.vue'),
  },
  data() {
    return {
      colunas,
      filtros: [
        {
          colunas: [
            'CadastroFederalSemMascara',
            'CadastroFederal',
            'RazaoSocial',
            'Modalidade',
            'DataPedido',
          ],
          valor: null,
        },
      ], // 'Negociacao', 'Modalidade', 'NumeroGrid'
      filtrosRapidos: [
        {
          id: 'Ativos',
          colunas: ['Situacao'],
          label: 'Ativos',
          valor: '1',
        },
        {
          id: 'StatusSemAtraso',
          colunas: ['StatusEmParcelamento'],
          label: 'Ativo(em dia)',
          valor: '1',
        },
        {
          id: 'StatusEmAtraso',
          colunas: ['StatusEmParcelamento'],
          label: 'Ativo(em atraso)',
          valor: '2',
        },
        {
          id: 'Liquidado',
          colunas: ['Situacao'],
          label: 'Líquidado',
          valor: '4',
        },
        {
          id: 'Rescindido',
          colunas: ['Situacao'],
          label: 'Rescindido',
          valor: '5',
        },
        {
          id: 'SemParcelamento',
          colunas: ['Situacao'],
          label: 'Sem parcelamento',
          valor: '13, 14',
        },
      ],
    }
  },
  methods: {
    getColorTag(value) {
      if (
        value === 'Rescindido'
        || value === 'Sem acesso para filial'
        || value === 'CPF sem dados'
      ) {
        return 'light-warning'
      }
      if (value === 'Ativo (em atraso)') {
        return 'light-danger'
      }
      return 'light-success'
    },
  },
}
</script>
