export default [{ label: 'Razão social', field: 'razaoSocial' },
  { label: 'CNPJ/CPF', field: 'cadastroFederal', tdClass: 'col-nowrap col-tamanho-cnpj' },
  { label: 'Nº parcelamento', field: 'identificacao' },
  { label: 'Modalidade', field: 'modalidade' },
  { label: 'Data negociação', field: 'dataPedidoCompetencia' },
  { label: 'Situação', field: 'situacao', type: 'custom' },
  { label: 'Parcela', field: 'nroUltimaParcelaDe', tdClass: 'alinhar-coluna' },
  { label: 'Última parcela lida', field: 'mesUltimaParcela' },
  { label: 'Parcelas em atraso', field: 'parcelasEmAtraso', type: 'custom' },
  { label: 'Consultado em', field: 'ultimaExecucao' },
]
